<template>
    <div style="margin-bottom:2rem;">
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go(index)" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
            {url:'case/5-1.png',title:'厦门首起非法买卖中国鲎案件，开审！',time:'2021-12-15',content:`中国鲎是一种古老的海洋物种，有“生物活化石”之称。独有的蓝色血液具有很高的科研及医用价值。`},
            {url:'case/5-2-1.png',title:'全国首例！历思助力技术调查官参审污染环境案件，宣判啦',time:'2020-11-17',content:`近日，福建某法院对被告人黄某污染环境罪一案依法公开开庭审理并当庭宣判。两名生态环境技术调查官首次出席参加庭审，成为全国首起在生态环境诉讼中引入技术调查官制度的案件。`},
            {url:'case/5-2-2.png',title:'历思承担厦门首起环境公益诉讼案环境损害司法鉴定工作',time:'2018-09-25',content:`没有办理任何资质，厦门市一男子却擅自在承包地上开设大规模养猪场，造成大面积环境污染。不久前，厦门市中级人民法院对原告福建省环保志愿者协会与被告叶某环境污染责任纠纷公益诉讼一案作出一审判决，`},
                
                ]
        }
    },
    methods:{
        go(index){
            let pathStr = '/case/env_case/'+(index+1)
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>